import React from "react";
import { useState } from "react";
import { oiseaux } from "../.data/oiseaux";
import SingleItem from "../components-secondary/single-item";

function Items() {

  const [search, setSearch] = useState('')
  const [list, setList] = useState(oiseaux)

  function handleChange(event) {
    // TODO manage what happens when ENTER key is pressed
    const filter = event.target.value;
    setSearch(filter)
    const newlist = oiseaux.filter((item) => {
      return ((item.name+item.meta+item.tags).toUpperCase().includes(filter.toUpperCase()))
    })
    setList(newlist)
  }
  function clearFilter() {
    setSearch('');
    setList(oiseaux);
  }

  return (
    <div className="p-5">
      <div className="p-1 row">
        <form className="row" onSubmit={(event)=>event.preventDefault()}>
          <div className="col-auto">
            <label className="">Rechercher un oiseau :</label>
          </div>
          <div className="col-5">
            <input className="form-control form-control-sm" placeholder="Search..." value={ search } onChange={handleChange} />
          </div>
          <div className="col-auto">
            <button className="btn btn-secondary btn-sm" onClick={()=>clearFilter()} ><i className="bi bi-x-circle me-1"></i> Clear </button>
          </div>
        </form>
      </div>

      {/* LISTING OF ALL ITEMS */}
      <div className="row py-4">
        { list.map((item, index) => <SingleItem item={item} key={index} />)}
      </div>
    </div>
  )
}

export default Items