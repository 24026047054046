import React from "react";

function LevelSelector({levelList, setCurrentLevel}) {

  function handleChange(event) {
    const value = event.target.value;
    setCurrentLevel(value)
  }


  return (
    <div className="level-selector row mx-auto">
      <span className="form-text mb-1">Couronne : </span>
      <form onSubmit={(event)=>event.preventDefault()}>
          <select className="form-select form-select-sm" aria-label="Default select example" onChange={handleChange}>
            {levelList.map((level,index) => 
              <option key={index} value={index}>{level.name}</option>
            )}
          </select>
      </form>
    </div>
  )
}

export default LevelSelector

/*
          <div className="col-3">
            <button className="btn btn-secondary" onClick={()=>clearFilter()} ><i className="bi bi-x-circle me-1"></i> Clear </button>
          </div>
          */
