import React from "react";

function JACOMode({ mode }) {

const demo = {imgPath:  `/img/demo${(mode==='demo' ?  '.svg' : '-inact.svg')}`,
              ledStyle: {'backgroundColor': (mode==='demo' ?  '#0075bf' : '#ccc') },
              textStyl: {color: (mode==='demo' ?  '#0075bf' : '#ccc'),fontSize:'12px'}
            }
const apprendre = { imgPath:  `/img/apprendre${(mode==='apprendre' ?  '.svg' : '-inact.svg')}`,
                    ledStyle: {'backgroundColor': (mode==='apprendre' ?  '#0075bf' : '#ccc') },
                    textStyl: {color: (mode==='apprendre' ?  '#0075bf' : '#ccc'),fontSize:'12px'}
}
const jouer = { imgPath:  `/img/jouer${(mode==='jouer' ?  '.svg' : '-inact.svg')}`,
                ledStyle: {'backgroundColor': (mode==='jouer' ?  '#0075bf' : '#ccc') },
                textStyl: {color: (mode==='jouer' ?  '#0075bf' : '#ccc'),fontSize:'12px'}
}

return (
  <div className="jaco-mode d-flex justify-content-around">
    <div className="d-flex flex-column align-items-center justify-content-between">
      <img className="jaco-mode-icon" src={demo.imgPath } alt="demo"/>
      <div className="jaco-mode-led"  style={demo.ledStyle}></div>
      <div className="text-uppercase text-center" style={demo.textStyl}>Démo</div>
    </div>
    <div className="d-flex flex-column align-items-center justify-content-between">
      <img className="jaco-mode-icon" src={apprendre.imgPath} alt="apprendre"/>
      <div className="jaco-mode-led"  style={apprendre.ledStyle}></div>
      <div className=" text-uppercase text-center" style={apprendre.textStyl}>Apprendre</div>
    </div>
    <div className="d-flex flex-column align-items-center justify-content-between">
      <img className="jaco-mode-icon" src={jouer.imgPath} alt="jouer"/>
      <div className="jaco-mode-led"  style={jouer.ledStyle}></div>
      <div className="text-uppercase text-center" style={jouer.textStyl}>Jouer</div>
    </div>
  </div>
)
}

export default JACOMode