import React from "react";
import { useState } from "react";
import { couronnes } from "../.data/couronnes";
import { oiseaux } from "../.data/oiseaux";
import SingleLevel from "../components-secondary/single-level";

function Levels() {

  const [search, setSearch] = useState('');
  const [list, setList] = useState(couronnes)

  function handleChange(event) {
    // TODO manage what happens when ENTER key is pressed
    const filter = event.target.value;
    setSearch(filter)
    const newlist = couronnes.filter((item) => {
      return ((item.name+item.desc+item.tags).toUpperCase().includes(filter.toUpperCase()))
    })
    setList(newlist)
    newlist.forEach(el=>levelImages(el))
  }

  function clearFilter() {
    setSearch('');
    setList(couronnes);
  }

  function levelImages(level) {
    const levelImgs = level.items.map(levelId => oiseaux.find(el => el.id === levelId).img)
    return levelImgs
  }


  return (
    <div className="p-5">
      <div className="p-1 row">
        <form className="row" onSubmit={(event)=>event.preventDefault()}>
          <div className="col-auto">
            <label className="">Rechercher une couronne :</label>
          </div>
          <div className="col-5">
            <input className="form-control form-control-sm" placeholder="Search..." value={ search } onChange={handleChange} />
          </div>
          <div className="col-auto">
            <button className="btn btn-secondary btn-sm" onClick={()=>clearFilter()} ><i className="bi bi-x-circle me-1"></i> Clear </button>
          </div>
        </form>
      </div>

      {/* LISTING OF ALL ITEMS */}
      <div className="row py-4">
        { list.map((item, index) => <SingleLevel item={item} images={levelImages(item)} key={index} />)}
      </div>
    </div>
  )
}

export default Levels