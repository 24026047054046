import React from "react";

function DisplayScore({score, setScore, remainingTime, setRemainingTime, maxRemainingTime}) {

  return (
    <div className="p-1" width="300px">
      <div className="display-score py-1 px-2 rounded shadow" style={{ 'backgroundColor':'#0075bf', 'width':'180px'}}>
        <p className="text-light text-center mb-0">SCORE : <span className="fw-bold">{score} points</span></p>
        <button className="btn btn-sm btn-light mx-auto d-block my-1" onClick={()=>setScore(0)}>Remise à zéro</button>
        <div className="progress my-1 mt-2">
          <div className="progress-bar bg-info" role="progressbar" style={{"width": (100*remainingTime/maxRemainingTime)+'%'}} aria-valuenow={remainingTime} aria-valuemin="0" aria-valuemax={maxRemainingTime}>{remainingTime} s</div>
        </div>
      </div>
    </div>
  )
}

export default DisplayScore