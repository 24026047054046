import React from 'react'
import positionArray from '../.data/item-positions'


function ItemBranch({item, id, borderColor, setClicked, setHovered}) {

  const itemCenterPosition = positionArray[id]

  const imgRadius = 100 // pixels
  const imgStyle={'height':imgRadius+'px'}
  const itemStyle={
    'cursor':'pointer',
    'position':'absolute',
    'top':itemCenterPosition.y-imgRadius/2,
    'left': itemCenterPosition.x-imgRadius/2}

  return (
    <div className="item-branch" style={itemStyle}>
      <img className={"rounded-circle "+borderColor} src={item.img} style={imgStyle} onClick={setClicked} onMouseOver={setHovered} alt={item.name}/>
      <hr />
    </div>
  )
}

export default ItemBranch