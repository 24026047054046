import React, {useState, useEffect} from "react";

// Game components
import JACOButtons from "../components-jaco/buttons";
import JACOMain from "../components-jaco/jacomain";
import LevelSelector from "../components-jaco/level-selector";
import JACOMode from "../components-jaco/mode";
import DisplayScore from "../components-jaco/score";
import ItemBranch from "../components-jaco/item-branch";
import PlaySound from "../components-jaco/play-sound"

// game data
import {couronnes} from '../.data/couronnes.js';
import {oiseaux} from '../.data/oiseaux.js';
import SingleItem from "../components-secondary/single-item";

function Game() {
  const modList = ['demo', 'apprendre', 'jouer'];
  const maxRemainingTime = 20;

  const [mode, setMode] = useState('apprendre')
  const [currentLevel, setCurrentLevel] = useState(0)
  const [score, setScore] = useState(0)
  const [ringColors, setRingColors] = useState(Array(13).fill('danger'))   // border-5 border-danger / success / info

  const [remainingTime, setRemainingTime] = useState(3)
  const [timeElapsed,   setTimeElapsed  ] = useState(false)
  const [itemClicked, setItemClicked] = useState(-1)
  const [itemHovered, setItemHovered] = useState(-1)
  const [itemToFind,  setItemToFind ] = useState(Math.floor(Math.random()*12) + 1)


  function setUniqueRing(index) {
    const borders = Array(13).fill('')
    borders[index] = `border border-5 border-info`
    setRingColors(borders)
  }
  function clearRings() {
    setRingColors(Array(13).fill(''))
  }   

  function setMp3(item) {
    if (mode!=='jouer') setUniqueRing(item)
    // console.log('playing mp3 item #',item+1)
    // console.log('for',oiseaux.find(oiseau=> oiseau.id === (couronnes[currentLevel].items[item])).name)
    // console.log('sound',oiseaux.find(oiseau=> oiseau.id === (couronnes[currentLevel].items[item])).sound)
  } 

  useEffect(() => {
    // if mode is "jouer" then remove all borders
    if (mode==='jouer') clearRings()
    else setUniqueRing(itemToFind)
  },[mode, itemToFind])

  useEffect(() => {
    if (itemClicked !== -1) {
      setItemClicked(-1);
      if (mode==='demo') return
      if (mode==='apprendre') {
        setItemToFind(itemClicked)
        setMp3(itemClicked)
      }
      if (mode==='jouer') {
        if (itemClicked === itemToFind) {
          window.alert('BRAVO !')
          setScore(score=> score+1)
          // new round
          const newItem = Math.floor(Math.random()*12) + 1
          // console.log('Cheat : Playing #',newItem+1)  // CHEAT !!!
          setMp3(newItem)
          setItemToFind(newItem)
          setRemainingTime(maxRemainingTime)
          setTimeElapsed(false)
        } else {
          window.alert(`Perdu, il fallait choisir le # ${itemToFind+1} - ${oiseaux.find(oiseau=> oiseau.id === (couronnes[currentLevel].items[itemToFind])).name}`)
          const newItem = Math.floor(Math.random()*12) + 1
          //new round
          // console.log('Cheat : Playing #',newItem+1)  // CHEAT !!!
          setMp3(newItem)
          setItemToFind(newItem)
          setRemainingTime(maxRemainingTime)
          setTimeElapsed(false)
        }
      }
    }
  // eslint-disable-next-line
  },[itemClicked, mode, itemToFind, currentLevel])

useEffect(() => {
  if (timeElapsed) {
    if (mode==='demo') {
      const newItem = Math.floor(Math.random()*12) + 1
      setMp3(newItem)
      setItemToFind(newItem)
      setRemainingTime(maxRemainingTime)
      setTimeElapsed(false)
    } else if (mode==='jouer') {
      window.alert(`Perdu, il fallait trouver le # ${itemToFind+1} - ${oiseaux.find(oiseau=> oiseau.id === (couronnes[currentLevel].items[itemToFind])).name}`)
      const newItem = Math.floor(Math.random()*12) + 1
      setMp3(newItem)
      setItemToFind(newItem)
      setRemainingTime(maxRemainingTime)
      setTimeElapsed(false)
    }
  }
  // eslint-disable-next-line
  },[timeElapsed, mode])

  useEffect(() => {
    const timer = window.setTimeout(() => {
      let curRemTime = remainingTime -1;                        // Remaining time decreases by default
      if (mode === "apprendre") curRemTime = maxRemainingTime   // set to max if the mode does not need time
      // Set time is up or save new time
      if (curRemTime<=0) {
        setTimeElapsed(true)
      } else {
        setRemainingTime(remainingTime => curRemTime)
      }
      }, 1000)

    return function () {
      clearInterval(timer)
    }
},[remainingTime, mode])

function changeMode() {
  const currentIndex = modList.findIndex(currMode => mode === currMode)
  const newModeIndex = (currentIndex +1) % modList.length;
  setMode(modList[newModeIndex])
}

  return (
    <>
    <JACOMain />
    <PlaySound url={oiseaux.find(oiseau=> oiseau.id === (couronnes[currentLevel].items[itemToFind])).sound} />
    <JACOMode mode={mode}/>
    <JACOButtons changeMode={changeMode} />
    <LevelSelector levelList={couronnes} setCurrentLevel={setCurrentLevel} />
    <DisplayScore score={score} setScore={setScore} remainingTime={remainingTime} maxRemainingTime={maxRemainingTime} setRemainingTime={setRemainingTime}/>
    { couronnes[currentLevel].items.map((itemid, index) => {
      const item = oiseaux.find(oiseau=> oiseau.id === itemid)
      return (
        <ItemBranch key={index} item={item} id={index} borderColor={ringColors[index]} setClicked={()=>setItemClicked(index)} setHovered={()=>setItemHovered(index)} />
      )
    }
    ) }
    <div className="jaco-right-panel d-flex flex-column justify-content-center d-none d-xl-block py-2">
      <div>
      {(itemHovered !== -1) && <SingleItem isShort item={oiseaux.find(oiseau=> oiseau.id === (couronnes[currentLevel].items[itemHovered]))} /> }
      </div>
    </div>
    </>
  )
}

export default Game