import React from "react";
import { NavLink } from "react-router-dom";
import Footer from "./footer";


function VerticalNavbar() {

  const menu = [
  {icon:"bi bi-x-diamond-fill",    text:"Acceuil",      link:"/"},
  {icon:"bi bi-controller",        text:"Jouer",        link:"/game"},
  // {icon:"bi bi-file-earmark-minus",text:"Les règles",   link:"/rules"},
  {icon:"bi bi-binoculars",        text:"Les oiseaux",  link:"/items"},
  {icon:"bi bi-list-ul",           text:"Les couronnes",link:"/levels"},
  {icon:"bi bi-question-circle",   text:"Présentation", link:"/infos"},
  {icon:"bi bi-person-lines-fill", text:"Contact",      link:"/contact"},
  ]

  return (
  <>
    <div className="navbar d-flex flex-column flex-shrink-0 p-3 text-bg-dark align-items-start" style={{"width":"200px"}}>
      <div className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none">
        <i className="bi bi-menu-button-wide mx-2"></i>
        <span className="fs-4">Menu</span>
      </div>
      <hr />
      <ul className="nav nav-pills flex-column mb-auto">
        {menu.map((el,index) => 
    <li className="nav-item" key={index}>
      <NavLink to={el.link} end className="nav-link text-white" aria-current="page">
        <i className={el.icon + " mx-2"}></i>
        {el.text}
      </NavLink>
    </li>
  )}
      </ul>
      <Footer />
    </div>
  </>
  )
}

export default VerticalNavbar

/*
        <NavLink to="#" className="d-flex align-items-center text-white text-decoration-none" aria-expanded="false">
          <img src="https://github.com/mdo.png" alt="" width="32" height="32" className="rounded-circle me-2" />
          <strong>mdo</strong>
        </NavLink>
*/