import React, { useEffect, useState } from "react";

const PlaySound = ({ url }) => {
  const [audio] = useState(new Audio(url));

  useEffect(() => {
    audio.src=url;
    audio.play()
  },[url, audio])

  return (
    <div className="sound-indicator" >
      {!audio.ended && <i className="bi bi-volume-up-fill" /> }
    </div>
      
  );
};

export default PlaySound;