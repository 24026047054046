import React from "react";

function JACOMain() {

  return (
    <div align="center">
      <img className="jaco-main" src="/img/jaco-main.svg" alt='Main display of the game'/>
    </div>
  )
}

export default JACOMain