export const couronnes = [
  { id:1,
    name:"Premières couronne",
    meta:"",
    desc:"Ceci est la permière couronne pour le test de toute l'appli",
    items: [1,2,3,4,5,6,7,8,9,10,11,12,13],
    tags: 'facile, marais'},

  { id:2,
    name:"Seconde couronne",
    meta:"",
    desc:"Ceci est la seconde couronne pour le test de toute l'appli",
    items: [14,15,16,17,18,19,20,21,22,23,24,25,26],
    tags:'mésanges, passereaux'},

  { id:3,
    name:"Dernière ouronnet et celle là, c'est vraiment la dernière couronne",
    meta:"",
    desc:"Ceci est la seconde couronne pour le test de toute l'appli",
    items: [1,3,5,7,9,11,13,14,16,18,22,2,6],
    tags:'mésanges, passereaux'},

]