import React from "react";
import { Routes, Route } from "react-router-dom";

// Compoenents Navbar
import VerticalNavbar from "./navbar/vertical-navbar";

// Components Pages
import Contact from "./components/contact";
import Game from "./components/game";
import HomePage from "./components/homepage";
import Infos from './components/infos'
import Items from "./components/items";
import Levels from "./components/levels";
// import Rules from "./components/rules";


function App() {

  return (
    <>
      <VerticalNavbar />
      <div style={{ "marginLeft":"200px"}}>
        <Routes>
          <Route exact path="/"        element={<HomePage />} />
          <Route exact path="/game"    element={<Game />} />
          {/* <Route exact path="/rules"   element={<Rules />} /> */}
          <Route exact path="/items"   element={<Items />} />
          <Route exact path="/levels"  element={<Levels />} />
          <Route exact path="/infos"   element={<Infos />} />
          <Route exact path="/contact" element={<Contact />} />
        </Routes>
      </div>
    </>
  )
}

export default App;
