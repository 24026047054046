import React from "react";
import { Link } from "react-router-dom";

function HomePage() {
  return (
    <div className="p-5">
      <div className="row justify-centent-center m-3">
        <div className="rounded-5 bg-orange-macareux p-4 mb-3 text-white col-10">
          {/* <h1>Bienvenue !</h1> */}
          <h2>JACO <i>digital</i></h2>
          <p>Minim non quis cupidatat magna amet. In in voluptate quis mollit do sit cupidatat occaecat ipsum ad dolor. Enim nostrud anim enim dolor occaecat aliqua eu amet sunt laborum aliquip consectetur aliqua. Dolor nisi nisi esse mollit culpa cillum laborum. Voluptate cupidatat veniam non commodo sit in sit. Est laborum exercitation tempor aute cillum non occaecat nostrud incididunt pariatur veniam enim quis.</p>
          <Link to='/game'><button className="btn btn-lg btn-light">Jouer maintenant !</button></Link>
        </div>
        <div className="col-5 rounded-5 border bg-light p-4">
          <p>Ex pariatur dolor ipsum irure est et voluptate sit labore cupidatat consectetur proident. Adipisicing veniam sint quis ut elit duis enim. Ullamco laboris irure officia excepteur consequat reprehenderit aliqua sunt dolor et sit commodo. Magna adipisicing aliquip amet veniam ullamco dolore ex et consequat amet laborum nisi enim exercitation.</p>
        </div>
        <div className="col-5">
          <img alt='Présentation img-responsive'  width="100%" height="auto" src='/img/logo-lpo.jpg' />
        </div>
      </div>
    </div>
  )
}

export default HomePage