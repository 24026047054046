import React from "react";

function Contact() {
  return (
    <div className="p-5">
      <div className="p-5 border rounded-5 shadow">
        <div className="row align-items-center">
          <div className="col-5 ms-3">
            <img className="rounded-4 img-responsive" width="100%" height="auto" src='/img/logo-lpo.jpg' alt="logo LPO"/>
            <h3>LPO Pas-de-Calais</h3>
            <p> 1b Place Roger Selngro<br/>
                BP 80060<br/>
                62510 ARQUES
            </p>
            <p><i class="bi bi-envelope me-2"></i><a href="mailto:pas-de-calais@lpo.fr">pas-de-calais@lpo.fr</a></p>
            <p></p>
          </div>
          <div className="col-2"></div>
          <div className="col-4 ms-3">
            <iframe width="350" height="350" id="gmap_canvas" src="https://maps.google.com/maps?q=1b%20place%20roger%20salengro,%2062510%20arques&t=&z=13&ie=UTF8&iwloc=&output=embed"
                    title="Google map" frameborder="0" scrolling="no" marginheight="0" marginwidth="0">
            </iframe>
          </div>
        </div>

      </div>
    </div>
  )
}

export default Contact