import React from "react";

function Footer() {
  return (
    <div className="footer text-center text-muted d-flex flex-column align-items-center">
      <hr />
      <p> 
        © JACO - 2022 <br />
        <a className='link-secondary' href='https://monsieurs.fr'>MonsieurS</a>
      </p>
    </div>
  )
}

export default Footer