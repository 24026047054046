import React from "react";

function JACOButtons({ changeMode }) {

const volMessage = (
"Cette fonction présente que sur le jeu physique. \r\n Utilisez le controle de volume de votre ordinateur.")

return (
  <div className="jaco-buttons d-flex justify-content-around">
      <img className="jaco-button-icon" style={{"cursor":"pointer"}} src="/img/mode.svg" onClick={()=>changeMode()}  alt='mode'/>
      <img className="jaco-button-icon" style={{"cursor":"pointer"}} src="/img/vol-dw.svg" onClick={()=>alert(volMessage)} alt='vol minus'/>
      <img className="jaco-button-icon" style={{"cursor":"pointer"}} src="/img/vol-up.svg" onClick={()=>alert(volMessage)} alt='vol plus'/>
  </div>
)
}

export default JACOButtons