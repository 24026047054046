// INPUTS
const pi = Math.PI;
const center = {x:640,y:362}  // coordinates of the center
const radius = 278            // pixels
const nb_of_items = 13;       // nb of brids on the game

// CALCS
const ang_division = 0.39
const start_angle = 2.375;
const positionArray = []

for (let index = 0; index < nb_of_items; index++) {
  const element_angle = start_angle + index * ang_division;
  const element_x = center.x + radius * Math.cos(element_angle)
  const element_y = center.y + radius * Math.sin(element_angle)
  positionArray.push({x:element_x,y:element_y})
}

export default positionArray

