// NOTE : Losrque la descriptions comporte des termes, ne pas hésiter à utiliser les 'templating strings' (alt gr + 7)
//        pour les sauts de lignes, utiliser... (TODO)

export const oiseaux = [
  { id: 1,
    name:"Mésange bleue",
    meta:"Cyanistes caeruleus",
    desc:"c’est le seul passereau en Europe au plumage bleu et jaune. Elle se distingue par une large calotte \
	 les ailes et une grande partie de la queue bleues. Les joues et le bord de la tête sont blancs avec une \
	 bande oculaire noirâtre. Le dos est gris verdâtre et le dessous jaune. Le plumage des deux sexes est \
	 quasiment identique. C’est une espèce sédentaire, répandue et très commune.",
    img: "/images/mesange-bleue.png",
    sound: "/sounds/mesange-bleue.mp3",
    tags: 'mésange, bleue, passereaux',
    link1:'https://www.lpo.fr/decouvrir-la-nature/fiches-especes/fiches-especes/oiseaux/mesanges/mesange-bleue',
    link2:'https://cdnfiles2.biolovision.net/www.oiseauxdesjardins.fr/userfiles/Fichesespces/FicheespceMB.pdf'},

  { id: 2,
    name:"Mésange charbonnière",
    meta:"Parus major",
    desc:"C’est la plus grande des mésanges et elle est facilement identifiable grâce à sa calotte, sa gorge et \
	 sa cravate noire, cette dernière étant plus épaisse chez le mâle que chez la femelle. Ses joues sont \
	 blanches et le reste de son plumage est de couleur jaune ou jaune vert à l’exception des ailes et de \
	 la queue qui sont plutôt noires. C’est une espèce sédentaire et largement répandue.",
    img: "/images/mesange-charbonniere.png",
    sound: "/sounds/mesange-charbonniere.mp3",
    tags: 'mésange, charbonniere, passereaux',
    link1:'https://www.lpo.fr/decouvrir-la-nature/fiches-especes/fiches-especes/oiseaux/mesanges/mesange-charbonniere',
    link2:'https://cdnfiles2.biolovision.net/www.oiseauxdesjardins.fr/userfiles/Fichesespces/FicheespceMC.pdf'},

  { id: 3,
    name:"Geai des chênes",
    meta:"Garrulus glandarius",
    desc:"Assez farouche, le Geai des chênes est impossible à confondre. Il arbore un plumage chamois roux avec \
	 ailes blanc et noir, et de très jolies plumes bleues zébrées de noir. Ses moustaches noires sont bien \
	 visibles et ses ailes paraissant très arrondies. En vol, on peut apercevoir son croupion blanc.",
    img: "/images/geai-des-chenes.png",
    sound: "/sounds/geai-des-chenes.mp3",
    tags: 'geai, passereaux',
    link1:'https://www.lpo.fr/decouvrir-la-nature/fiches-especes/fiches-especes/oiseaux/geai-des-chenes',
    link2:'https://cdnfiles2.biolovision.net/www.oiseauxdesjardins.fr/userfiles/Fichesespces/FicheespceGDC.pdf'},

  { id: 4,
    name:"Accenteur Mouchet",
    meta:"Prunella modularis",
    desc:"L’Accenteur mouchet Prunella modularis est une espèce commune, comparable au Moineau domestique Passer \
	 domesticus. Les deux espèces sont souvent confondues. Buffonl’avait surnommé le « traîne-buisson »",
    img: "/images/accenteur-mouchet.png",
    sound: "/sounds/accenteur-mouchet.mp3",
    tags: ', passereaux',
    link1:'https://www.lpo.fr/decouvrir-la-nature/fiches-especes/fiches-especes/oiseaux/accenteur-mouchet',
    link2:'https://cdnfiles2.biolovision.net/www.oiseauxdesjardins.fr/userfiles/Fichesespces/FicheespceACM.pdf'},

  { id: 5,
    name:"Bouvreuil pivoine",
    meta:"Pyrrhula pyrrhula",
    desc:"Oiseau trapu avec une grosse tête. Le mâle a le manteau gris. Le croupion est blanc, contrastant \
	 avec la queue noirâtre. Les ailes sont noirâtres avec une seule barre alaire claire. Les parties \
	 inférieures sont rouge-rose et le bas-ventre est blanc. La tête a la calotte, la nuque les lores \
	 et le menton noirs. Les joues et la gorge sont rouge-rose. Les yeux sont noirs. La calotte est \
	 bleu-noir avec des reflets brillants. Le bec noirâtre est court et conique, situé assez bas sur la face. \
	 Les pattes et les doigts sont brun rosâtre.",
    img: "/images/bouvreuil-pivoine.png",
    sound: "/sounds/bouvreuil-pivoine.mp3",
    tags: ', passereaux',
    link1:'https://www.lpo.fr/decouvrir-la-nature/fiches-especes/fiches-especes/oiseaux/bouvreuil-pivoine',
    link2:'https://cdnfiles2.biolovision.net/www.oiseauxdesjardins.fr/userfiles/Fichesespces/FicheespceBP.pdf'},

  { id: 6,
    name:"Chardonneret élégant",
    meta:"Carduelis carduelis",
    desc:"Le chardonneret élégant adulte est facilement reconnaissable à son masque facial rouge et noir. \
	Le chardonneret immature n’a pas de rouge ni de noir à la tête. Celle-ci est marron clair. Les mâles \
	 et femelles sont identiques de loin : on dit qu’il n’y a pas de dimorphisme sexuel apparent. Le bec est \
	 celui d’un granivore, il est relativement long et fin. Les plumes de la queue sont courtes, noires avec \
	 des taches blanches caractéristiques. L’oiseau en vol se distingue à son croupion blanc. Le chardonneret \
	 recherche en hiver des petite graines au sol ou sur les mangeoires plateaux.",
    img: "/images/chardonneret-elegant.png",
    sound: "/sounds/chardonneret-elegant.mp3",
    tags: ', passereaux',
    link1:'https://www.lpo.fr/decouvrir-la-nature/fiches-especes/fiches-especes/oiseaux/chardonneret-elegant',
    link2:'https://cdnfiles2.biolovision.net/www.oiseauxdesjardins.fr/userfiles/Fichesespces/FicheespceCEG.pdf'},

  { id: 7,
    name:"Fauvette à tête noire",
    meta:"Sylvia atricapilla",
    desc:"Passereau gris de la famille des Sylviidae. Le mâle se distingue par une calotte noire ; la femelle \
	 et les juvéniles par leur « casquette » rousse. le chant est puissant, c’est une mélodie flûtée très \
	 agréable, plus courte que celle de la fauvette des jardins et plus variée.",
    img: "/images/fauvette-a-tete-noire.png",
    sound: "/sounds/fauvette-a-tete-noire.mp3",
    tags: ', passereaux',
    link1:'https://www.lpo.fr/decouvrir-la-nature/fiches-especes/fiches-especes/oiseaux/fauvette-a-tete-noire',
    link2:'https://cdnfiles2.biolovision.net/www.oiseauxdesjardins.fr/userfiles/Fichesespces/FicheespceFTN.pdf'},

  { id: 8,
    name:"Grimpereau des jardins",
    meta:"Certhia brachydactyla",
    desc:"Le grimpereau des jardins possède un bec incurvé vers le bas. Dessus marron finement moucheté. \
	 Large sourcil blanc crème. Ventre blanchâtre.",
    img: "/images/grimpereau-des-jardins.png",
    sound: "/sounds/grimpereau-des-jardins.mp3",
    tags: ', passereaux',
    link1:'https://www.lpo.fr/decouvrir-la-nature/fiches-especes/fiches-especes/oiseaux/grimpereau-des-jardins',
    link2:'https://www.oiseauxdesjardins.fr/index.php?m_id=20112'},

  { id: 9,
    name:"Grive musicienne",
    meta:"Turdus philomelos",
    desc:"La plus insignifiante des grives. Elle peut être facilement confondue avec la femelle du Merle noir \
	 Elle est cependant plus petite, son ventre est blanchâtre avec les flancs couleur beige. Il est ponctué \
	 de petites taches noires rondes.",
    img: "/images/grive-musicienne.png",
    sound: "/sounds/grive-musicienne.mp3",
    tags: ', passereaux',
    link1:'https://www.lpo.fr/decouvrir-la-nature/fiches-especes/fiches-especes/oiseaux/grive-musicienne',
    link2:'https://cdnfiles2.biolovision.net/www.oiseauxdesjardins.fr/userfiles/Fichesespces/FicheespceGMcpsv2_2.pdf'},

  { id: 10,
    name:"Hirondelle de fenêtre",
    meta:"Delichon urbicum",
    desc:"L’Hirondelle de fenêtre se distingue par son croupion blanc, le dessus de son corps bleu méthilène foncé, \
	 son ventre blanc pur et sa queue fourchue moyenne. Les juvéniles ont le plumage plus terne que les adultes",
    img: "/images/hirondelle-de-fenetre.png",
    sound: "/sounds/hirondelle-de-fenetre.mp3",
    tags: ', passereaux',
    link1:'https://www.lpo.fr/decouvrir-la-nature/fiches-especes/fiches-especes/oiseaux/hirondelles-martinets/hirondelle-de-fenetre',
    link2:'https://cdnfiles2.biolovision.net/www.oiseauxdesjardins.fr/userfiles/Fichesespces/FicheespceHF.pdf'},

  { id: 11,
    name:"Hirondelle de rivage",
    meta:"Riparia riparia",
    desc:"C’est un petit passereau, à ailes pointues, et adapté à une vie aérienne. Elle est de couleur gris \
	 brunâtre sur le dessus et de couleur blanche sur le ventre avec une bande pectorale brune. Sa queue \
	 est peu profondément fourchue. Son bec fin de couleur noir indique un régime insectivore.",
    img: "/images/hirondelle-de-rivage.png",
    sound: "/sounds/hirondelle-de-rivage.mp3",
    tags: ', passereaux',
    link1:'https://www.lpo.fr/decouvrir-la-nature/fiches-especes/fiches-especes/oiseaux/hirondelles-martinets/hirondelle-de-rivage',
    link2:'https://www.oiseauxdesjardins.fr/index.php?m_id=20112'},

  { id: 12,
    name:"Hirondelle rustique",
    meta:"Hirundo rustica",
    desc:"L’Hirondelle rustique est reconnaissable à sa gorge rouge brique et à son dessus bleu foncé aux reflets \
	 bleu métalliques qui contraste avec le dessous ivoire lavé de roux.",
    img: "/images/hirondelle-rustique.png",
    sound: "/sounds/hirondelle-rustique.mp3",
    tags: ', passereaux',
    link1:'https://www.lpo.fr/decouvrir-la-nature/fiches-especes/fiches-especes/oiseaux/hirondelles-martinets/hirondelle-rustique',
    link2:'https://cdnfiles2.biolovision.net/www.oiseauxdesjardins.fr/userfiles/Fichesespces/FicheespceHR.pdf'},
  

  { id: 13,
    name:"Martinet noir",
    meta:"Apus apus",
    desc:"Le martinet noir possède un corps aérodynamique et de longues ailes en forme de faux ainsi qu'une \
	 queue effilée. Cet oiseau est taillé pour le vol. Ses pattes sont très courtes et réduites. Elles ne \
	 lui permettent pas de se poser sur un fil ou une branche, mais seulement de s'agripper maladroitement \
	 aux parois verticales. De couleur noire avec une petite tache blanche sous la gorge, pas toujours \
	 visible en vol.",
    img: "/images/martinet-noir.png",
    sound: "/sounds/martinet-noir.mp3",
    tags: ', passereaux',
    link1:'https://www.lpo.fr/decouvrir-la-nature/fiches-especes/fiches-especes/oiseaux/hirondelles-martinets/martinet-noir',
    link2:'https://www.oiseauxdesjardins.fr/index.php?m_id=20112'},
  

  { id: 14,
    name:"Merle noir",
    meta:"Turdus merula",
    desc:"Le mâle se distingue de la femelle par son plumage tout noir avec un bec jaune orangé et un cercle \
	 oculaire jaune. La femelle est plus terne avec un plumage marron clair et surtout des mouchetures sur \
	 la poitrine. Elle a son bec est brunâtre et le cercle oculaire est peu visible contrairement au mâle. \
	 Les jeunes ont un plumage ressemblant fortement à celui des femelles avec des parties typiquement plus \
	 claires sur la poitrine. Les pattes sont brunes chez les deux sexes.",
    img: "/images/merle-noir.png",
    sound: "/sounds/merle-noir.mp3",
    tags: ', passereaux',
    link1:'https://www.lpo.fr/decouvrir-la-nature/fiches-especes/fiches-especes/oiseaux/merle-noir',
    link2:'https://cdnfiles2.biolovision.net/www.oiseauxdesjardins.fr/userfiles/Fichesespces/FicheespceMERLEN.pdf'},
  

  { id: 15,
    name:"Moineau domestique",
    meta:"Passer domesticus",
    desc:"Le moineau domestique a le corps large et charpenté, une tête assez grosse et un gros bec. Son dos est \
	 brunâtre densément strié de noir. Plumage souvent ébouriffé avec une attitude ramassée. Tête et pattes \
	 rentrées une fois perché. Il existe une différence apparente entre le mâle et la femelle. Le mâle porte \
	 une large bavette noire sur la gorge et la poitrine. Le dessus de la tête est gris cendré, le ventre \
	 grisâtre et le dos marron chocolat. La femelle se distingue du mâle par son plumage beaucoup plus terne, \
	 un sourcil crème derrière l’œil, son dos est marron écrémé.",
    img: "/images/moineau-domestique.png",
    sound: "/sounds/moineau-domestique.mp3",
    tags: ', passereaux',
    link1:'https://www.oiseauxdesjardins.fr/index.php?m_id=15&speciesFilter=&frmSpecies=366&frmDisplay=Afficher',
    link2:'https://www.lpo.fr/decouvrir-la-nature/fiches-especes/fiches-especes/oiseaux/moineau-domestique'},
  

  { id: 16,
    name:"Pic epeiche",
    meta:"Dendrocopos major",
    desc:"Le pic épeiche se reconnaît facilement à son plumage noir et blanc et les plumes situées sous la queue \
	 son rouges .Mâles, femelles et juvéniles sont facilement identifiables : les deux adultes ont une calotte \
	 noire. Seul le mâle possède une tâche rouge sur la nuque. Les juvéniles ont le dessus de la tête entièrement \
	 rouge.",
    img: "/images/pic-epeiche.png",
    sound: "/sounds/pic-epeiche.mp3",
    tags: ', passereaux',
    link1:'https://www.lpo.fr/decouvrir-la-nature/fiches-especes/fiches-especes/oiseaux/pics/pic-epeiche',
    link2:'https://cdnfiles2.biolovision.net/www.oiseauxdesjardins.fr/userfiles/Fichesespces/FicheespcePE.pdf'},
  

  { id: 17,
    name:"Pie bavarde",
    meta:"Pica pica",
    desc:"La Pie bavarde est un grand corvidé noir et blanc à longue queue. L’adulte a la tête, la poitrine, le dos \
	 et le bas-ventre noirs. Les ailes et la queue présentent un plumage au reflet bleu-vert métallique. \
	 L’abdomen est d’un blanc pur. La queue est longue, graduée, avec une bande à reflets violets près de \
	 l’extrémité. Le bec puissant est noir. Les yeux sont brun foncé. Les pattes et les doigts sont noirs.",
    img: "/images/pie-bavarde.png",
    sound: "/sounds/pie-bavarde.mp3",
    tags: ', passereaux',
    link1:'https://www.lpo.fr/decouvrir-la-nature/fiches-especes/fiches-especes/oiseaux/pie-bavarde',
    link2:'https://cdnfiles2.biolovision.net/www.oiseauxdesjardins.fr/userfiles/Fichesespces/FicheespcePB.pdf'},
  

  { id: 18,
    name:"Pigeon ramier",
    meta:"Columba palumbus",
    desc:"Le pigeon ramier a des tâches blanches sur les côtés du cou et la poitrine tinté-rosée. Les marques blanches \
	 sur les ailes sont très visibles en vol. C’est un oiseau principalement granivore qui prélève sa nourriture \
	 au sol. Il fait un nid peu élaboré fait de brindilles posées sur sur une branche ou une enfourchure.",
    img: "/images/pigeon-ramier.png",
    sound: "/sounds/pigeon-ramier.mp3",
    tags: ', passereaux',
    link1:'https://www.lpo.fr/decouvrir-la-nature/fiches-especes/fiches-especes/oiseaux/pigeons-tourterelles/pigeon-ramier',
    link2:'https://cdnfiles2.biolovision.net/www.oiseauxdesjardins.fr/userfiles/Fichesespces/FicheespcePR.pdf'},
  

  { id: 19,
    name:"Pinson des arbres",
    meta:"Fringilla coelebs",
    desc:"Le pinson des arbres et un passereau svelte, à longue queue. La différence entre le mâle et la femelle est \
	 marqué : le mâle a la tête grise, la poitrine et le dos rouge orangé, les ailes noires avec une barres \
	 alaires blanches ; la femelle est terne avec des rectrices externes blanches. Quant aux juvéniles ils sont \
	 semblable à la femelle, mais en plus clair.",
    img: "/images/pinson-des-arbres.png",
    sound: "/sounds/pinson-des-arbres.mp3",
    tags: ', passereaux',
    link1:'https://www.lpo.fr/decouvrir-la-nature/fiches-especes/fiches-especes/oiseaux/pinson-des-arbres',
    link2:'https://cdnfiles2.biolovision.net/www.oiseauxdesjardins.fr/userfiles/Fichesespces/FicheespcePDA.pdf'},
  

  { id: 20,
    name:"Pic vert",
    meta:"Picus viridis",
    desc:"Il se distingue par sa grande taille, son plumage vert et sa calotte rouge sur la tête. les mâles ont \
	 une tâche rouge au centre de la moustache noire alors que les femelles ont des moustaches entièrement noires. \
	 Le juvénile est très moucheté. L’oiseau est assez farouche. Il se nourrit très souvent à terre de fourmis \
	 grace à sa grande langue.",
    img: "/images/pic-vert.png",
    sound: "/sounds/pic-vert.mp3",
    tags: ', passereaux',
    link1:'https://www.lpo.fr/decouvrir-la-nature/fiches-especes/fiches-especes/oiseaux/pics/pic-vert',
    link2:'https://www.oiseauxdesjardins.fr/index.php?m_id=20112'},
  

  { id: 21,
    name:"Pouillot véloce",
    meta:"Phylloscopus collybita",
    desc:"Il a le dessus brun et le dessous gris fauve. Un oeil sombre avec sourcil peu marqué lui dessine le visage, \
	 et a des pattes généralement sombres. Il se distingue par son chant un peu métalliques, Dans certaines \
	 localités de France, il est nommé familièrement le compteur d’écus car son chant évoque des pièces que l’on \
	 jette dans une bourse.",
    img: "/images/pouillot-veloce.png",
    sound: "/sounds/pouillot-veloce.mp3",
    tags: ', passereaux',
    link1:'https://www.lpo.fr/decouvrir-la-nature/fiches-especes/fiches-especes/oiseaux/pouillot-veloce',
    link2:'https://cdnfiles2.biolovision.net/www.oiseauxdesjardins.fr/userfiles/Fichesespces/FicheespcePVEL.pdf'},
  

  { id: 22,
    name:"Rougegorge familier",
    meta:"Muscicapidae",
    desc:"Il appartient à la famille des Muscicapidés, regroupant les merles, grives, rossignols, rougequeues... \
	 Son allure est caractéristique de cette famille c'est a dire bec fin, droit et pointu, tête haute et \
	 corps dressé lorsqu’il sautille ou s’arrête brusquement, rondelet et haut sur pattes. Son œil est sombre \
	 de grande taille lui permet de rechercher sa nourriture dans la pénombre des broussailles, dès l’aurore ou \
	 au crépuscule.",
    img: "/images/rougegorge-familier.png",
    sound: "/sounds/rougegorge-familier.mp3",
    tags: ', passereaux',
    link1:'https://www.lpo.fr/decouvrir-la-nature/fiches-especes/fiches-especes/oiseaux/rougegorge-familier',
    link2:'https://cdnfiles2.biolovision.net/www.oiseauxdesjardins.fr/userfiles/Fichesespces/FicheespceRGF_1.pdf'},
  

  { id: 23,
    name:"Rougequeue noir",
    meta:"Phoenicurus ochruros",
    desc:"C’est grâce au chant qu’on le repère alors qu’il est généralement perché sur un point haut. Le mâle, \
	 au printemps, arbore une livrée entièrement noire, hormis une tache alaire plus ou moins blanche et \
	 la queue rousse. Souvent agité de mouvements brusques, le corps dressé sur de longues pattes, il n’hésite \
	 pas à piquer rapidement sur un insecte ou à l’attraper au vol.",
    img: "/images/rougequeue-noir.png",
    sound: "/sounds/rougequeue-noir.mp3",
    tags: ', passereaux',
    link1:'https://www.lpo.fr/decouvrir-la-nature/fiches-especes/fiches-especes/oiseaux/rougequeue-noir',
    link2:'https://cdnfiles2.biolovision.net/www.oiseauxdesjardins.fr/userfiles/Fichesespces/FicheespceRQN.pdf'},
  

  { id: 24,
    name:"Serin cini",
    meta:"Serinus serinus",
    desc:"le Serin cini est un petit passereau dont le mâle est jaune citron et la femelle est plus brun crème, \
	 ils son tout deux strié de brun foncée, la femelle l'es plus que le mâle. les juvenile, eux, sont très \
	 striésur le dessus comme sur le desous et ils portent des bandes rousses-pale sur les aile qui son visible \
	 en vole",
    img: "/images/serin-cini.png",
    sound: "/sounds/serin-cini.mp3",
    tags: ', passereaux',
    link1:'https://www.lpo.fr/decouvrir-la-nature/fiches-especes/fiches-especes/oiseaux/serin-cini',
    link2:'https://cdnfiles2.biolovision.net/www.oiseauxdesjardins.fr/userfiles/Fichesespces/FicheespceSC.pdf'},
  

  { id: 25,
    name:"Sittelle torchepot",
    meta:"Sitta europaea",
    desc:"Corps trapu avec tête relativement grande et bec long et pointu. Le dessus de la tête, le dos et la queue \
	 sont d’un gris-bleu ardoisé. Le dessous est beige roussâtre avec des flancs marron brunâtres chez le mâle. \
	 Joues et gorge sont blanchâtres. Une bande noire traverse l’œil de la base du bec jusqu’à la nuque. La queue \
	 est courte. Très caractéristique est sa faculté de descendre les troncs d’arbres tête en bas.",
    img: "/images/sitelle-torchepot.png",
    sound: "/sounds/sitelle-torchepot.mp3",
    tags: ', passereaux',
    link1:'https://www.lpo.fr/decouvrir-la-nature/fiches-especes/fiches-especes/oiseaux/sittelle-torchepot',
    link2:'https://cdnfiles2.biolovision.net/www.oiseauxdesjardins.fr/userfiles/Fichesespces/FicheespceST.pdf'},
  

  { id: 26,
    name:"Tourterelle turque",
    meta:"Streptopelia decaocto",
    desc:"Commune et peu farouche, de taille moyenne, elle est élancée et assez élégante.Ces couleur chamois grisâtre \
	 et rosé-brun avec une longue queue la rende très reconaissable. une Petite zone blanche et noire formant un \
	 demi collier ce voie sur la nuque.",
    img: "/images/tourterelle-turque.png",
    sound: "/sounds/tourterelle-turque.mp3",
    tags: ', passereaux',
    link1:'https://www.lpo.fr/decouvrir-la-nature/fiches-especes/fiches-especes/oiseaux/pigeons-tourterelles/tourterelle-turque',
    link2:'https://cdnfiles2.biolovision.net/www.oiseauxdesjardins.fr/userfiles/Fichesespces/FicheespceTT.pdf'},
  

  { id: 27,
    name:"Troglodyte mignon",
    meta:"Troglodytes troglodytes",
    desc:"Le troglodyte mignon est l’un des plus petits oiseaux d’Europe avec les roitelets. Il se reconnaît à son \
	 chant puissant. Son aspect marron brun et sa fâcheuse manie de se tenir caché dans les fourrés en font un \
	 oiseau difficile à observer. A découvert, celui-ci se distingue par son large sourcil crème et sa queue \
	 redressée. lorsqu'il ouvre le bec on peut voir qu'il est jaune orangé à l'interieur, et marron à l'exterieur",
    img: "/images/troglodyte-mignon.png",
    sound: "/sounds/troglodyte-mignon.mp3",
    tags: ', passereaux',
    link1:'https://www.lpo.fr/decouvrir-la-nature/fiches-especes/fiches-especes/oiseaux/troglodyte-mignon',
    link2:'https://cdnfiles2.biolovision.net/www.oiseauxdesjardins.fr/userfiles/Fichesespces/FicheespceTM.pdf'},
  

  { id: 28,
    name:"Verdier d'Europe",
    meta:"Chloris chloris",
    desc:"Un peu plus gros que le moineau domestique. Il a un bec épais couleur ivoire-rose, des barres alaires \
	 jaunes, un plumage globalement vert nuancer de gris et de jaune, surtout sur les ailes ou du noir peut \
	 aussi etre remarquée. et une queue courte à bord jaune et au centre relativement noir.",
    img: "/images/verdier-d-europe.png",
    sound: "/sounds/verdier-d-europe.mp3",
    tags: ', passereaux',
    link1:'https://www.lpo.fr/decouvrir-la-nature/fiches-especes/fiches-especes/oiseaux/verdier-d-europe',
    link2:'https://cdnfiles2.biolovision.net/www.oiseauxdesjardins.fr/userfiles/Fichesespces/FicheespceVE.pdf'},
  
]
