import React from "react";

function SingleLevel({item, images}) {

  return(
<div className="card col mx-3" >
  <div className="card-body">
    <h5 className="card-title">{item.name}</h5>
    <p>{ item.tags.split(', ').map((tag, i) => <span key={i} className="badge text-bg-info text-uppercase mx-1">{tag}</span>)} </p>
    <p className="card-text fs-8">{item.desc}</p>
    { images.map((el, index) => 
      <img key={index} src={el} alt="" width="45" height="45" className="rounded-circle border border-scondary mx-1" />
      )}
  </div>
</div>
  )
}

export default SingleLevel