import React from "react";

function SingleItem({item, isShort}) {
  // col-3 when fully displayed (lis items)
  // col   when short version (game side panel)
  const colx = isShort ? 'col' : 'col-3'

  return(
<div className={"card m-4 "+colx} >
  <img src={item.img} className="card-img-top img mx-auto d-block" alt={item.name} />
  <div className="card-body">
    <h5 className="card-title">{item.name}</h5>
    <p className="fst-italic"> {item.meta}</p>
    <p>{ item.tags.split(', ').map((tag, i) => <span key={i} className="badge text-bg-info text-uppercase mx-1">{tag}</span>)} </p>
    {!isShort && (<>
      <hr style={{"borderTop":"2px solid grey","width":"100%"}} />
      <p>
        <audio controls>
          <source src={ item.sound } type="audio/mpeg" />
          Not supported
        </audio>
      </p>
    <hr style={{"borderTop":"2px solid grey","width":"100%"}} />
    </>)}
    <p className="card-text fs-8">{item.desc}</p>
    {!isShort && (<>
      <hr style={{"borderTop":"2px solid grey","width":"100%"}} />
      <p className="text-center form-text">Plus d'infos sur Oiseaux des Jardins.fr</p>
      <div className="mx-2 d-flex justify-content-around">
        <button className="btn btn-success btn-sm" onClick={()=>window.open(item.link1, '_blank').focus()} >En ligne</button>
        <button className="btn btn-danger btn-sm" onClick={()=>window.open(item.link2, '_blank').focus()} >Au format PDF</button>
      </div>
    </>)}
  </div>
  </div>
  )
}

export default SingleItem