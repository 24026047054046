import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter} from 'react-router-dom'
import App from './App';
//import {AppProvider} from './context.js'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {/*<AppProvider>*/}
      <BrowserRouter>
        <App />
      </BrowserRouter>
    {/*</AppProvider>*/}
  </React.StrictMode>
);
