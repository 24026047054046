import React from "react";

function Infos() {
  return (
    <div className="p-4">
      <div className="">
        <h1 className="text-center my-4">Jeux d'Apprentissage aux Chants d’Oiseaux</h1>
        <div className="d-flex justify-content-center my-4 flex-column align-items-center">
          <div className="p-4 border rounded-5 shadow d-flex">
            <img className="text-center py-3" width="300px" src='/img/JACO_final.png' alt='Le jeu JACO'/>
          </div>
          <p className="text-center form-text fs-6"><i>Le jeu JACO dans sa verion physique <br />(Photo LPO France)</i></p>
        </div>
        <div className="row d-flex justify-content-center">
        <div className="col-8">
          <h3 className="form-text text-underline"><u> Présentation </u></h3>
          <p><strong>JACO</strong> (Jeux d'Apprentissage aux Chants d'Oiseaux) est un outils pédagogique développé 
          par la LPO Pas-De-Calais pour faciliter l'apprentissage des champs d'oiseaux.
          </p>
          <p>Dans sa version <u>physique</u>, il est livré avec 1 couronne bi-face permettant d'apprendre les Chants
            de <strong>26</strong> éspèces différentes et avec les accéssoires suivants :
          </p>
          <ul>
            <li><strong>1</strong> couronne bi-face (26 espèces et leurs chants)</li>
            <li><strong>1</strong> chargeur (le jeu fonctionne également sur batterie)</li>
            <li><strong>2</strong> supports en acrylique transparent</li>
          </ul>
          <h3 className="form-text text-underline"><u> Il permet de : </u></h3>
          <ul>
            <li>Faire découvrir les chants d’oiseaux à tout type de public</li>
            <li>Développer l’initiation et l’apprentissage aux chants d‘oiseaux (oiseaux des parcs et jardins)</li>
            <li>Attirer le public sur stands (l’outil sera conçu pour tenir sur batterie et via branchement)</li>
            <li>Utiliser en animation pour tout public, et sur diverses thématiques (Refuge LPO par exemple)</li>
          </ul>


          <h3 className="form-text text-underline"><u> Présentation </u></h3>
          <p>Fugiat reprehenderit elit ipsum enim laborum duis nisi nisi exercitation esse. Tempor labore ipsum elit velit labore consequat deserunt. Lorem adipisicing labore dolor adipisicing labore veniam ipsum minim tempor fugiat. Esse dolor non et eu magna magna.</p>
          </div>
        </div>

      </div>
    </div>
  )
}

export default Infos